// 企业信息
// sle
<template>
  <div class="main" :style="{ minHeight: minHeight + 'px' }">
    <page-head title="使用详情" />
    <div class="version flex-row justify-between">
      <div class="left flex-row align-center">
        <div>当前版本：</div>
        <a-tooltip>
          <template #title>{{ version.versionMembers }}</template>
          <img
            :src="version.versionIcon"
            v-if="version.versionIcon != ''"
            class="icon"
          />
          {{ version.versionName }}
        </a-tooltip>
      </div>
      <div class="right flex-row align-center">
        <div class="text" style="margin-right: 20px">
          有效到期时间： {{ version.expireTimeS }} {{ pastDue ? "（已过期）" : "" }}
        </div>
        <div class="button">
          <a-button @click="upgradeRenewal">升级续费</a-button>
        </div>
      </div>
    </div>
    <a-spin :spinning="spinning">
      <a-empty
        description="暂无数据"
        v-if="service.length == 0 && notAdd.length == 0"
      />
      <div class="service flex-row justify-between">
        <div
          v-for="item in service"
          :key="item.id"
          class="serviceItem flex-column justify-between"
        >
          <div class="top">
            <div class="title">{{ item.serviceName }}</div>
            <div
              class="num flex-row justify-between"
              v-if="!item.isConsumption"
            >
              <div>
                {{ item.consumeNum }}{{ item.serviceUnit }}/{{ item.allNum
                }}{{ item.serviceUnit }}
              </div>
              <div>可用： {{ item.remainingNum }}{{ item.serviceUnit }}</div>
            </div>
            <a-progress
              class="progress"
              :percent="(item.consumeNum / item.allNum) * 100"
              :showInfo="false"
              v-if="!item.isConsumption"
            />
            <div class="canUse" v-if="item.isConsumption">
              当前可用{{ item.remainingNum }}{{ item.serviceUnit }}
            </div>
          </div>
          <div class="bottom flex-column">
            <div class="line_h"></div>
            <div class="buttons flex-row justify-between align-center">
              <div>
                <a
                  :class="isDemo ? 'no_purchase' : 'purchase'"
                  v-if="item.purchase === true && item.serviceState === 0"
                  :title="isDemo ? '暂不提供购买' : ''"
                  @click="isDemo ? '' : purchaseClick(item)"
                  >购买</a
                >
                <b class="purchase" v-if="item.serviceState != 0">{{
                  item.remark
                }}</b>
              </div>
              <a class="log" v-if="item.hasLogPort" @click="logClick(item)"
                >日志详情</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="no_add" v-if="notAdd.length !== 0">未添加</div>
      <div class="service flex-row justify-between">
        <div
          v-for="item in notAdd"
          :key="item.id"
          class="serviceItem flex-column justify-between"
        >
          <div class="top">
            <div class="title">{{ item.serviceName }}</div>
            <div class="canUse can_num">可试用数量{{ item.freeNumber }}次</div>
          </div>
          <div class="bottom flex-column">
            <div class="line_h"></div>
            <div class="buttons flex-row justify-between align-center">
              <div>
                <a
                  :class="disabledBtn === true ? 'no_purchase' : 'purchase'"
                  :title="disabledBtn === true ? '暂无权限' : ''"
                  @click="disabledBtn === true ? '' : addClick(item)"
                  >添加</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>

  <usage-record ref="usageRecord" />
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { minheight } from '../components/sameVariable'
import PageHead from '@/components/PageHead'
import UsageRecord from './UsageRecord '

export default defineComponent({
  components: {
    'usage-record': UsageRecord,
    'page-head': PageHead,
  },
  data () {
    return {
      spinning: false, // 是否加载
      isDemo: false, // 是否演示租户
      pastDue: false, // 已过期
      version: {
        versionName: '', // 版本描述
        versionIcon: '', // 会员icon
        versionMembers: '', // 会员描述
        expireTime: '', // 有效期
      }, // 版本信息
      service: [{
        id: '',
        serviceName: '应用信息',
        isConsumption: true,
        hasLogPort: true,
        logPort: '',
        consumeNum: 0,
        purchase: true,
        serviceUnit: '次',
        serviceState: 0,
        remark: '',
      }], // 企业服务
      notAdd: [{
        id: '',
        serviceName: '应用信息',
        isConsumption: true,
        hasLogPort: true,
        logPort: '',
        consumeNum: 0,
        purchase: true,
        serviceUnit: '次',
        serviceState: 0,
        remark: '',
        disabledBtn: false,
      }], // 企业服务
    }
  },
  // 页面加载事件
  created () {
    this.minHeight = minheight
    this.getEnterpriseVersion()
    this.getEnterpriseServiceList()
    this.getEnterpriseNotAddList()
    const tenantList = JSON.parse(localStorage.getItem('tenantList'))
    this.isDemo = tenantList.isDemo
    this.disabledBtn = false
    // if (tenantList.isFree === true) {
    //   this.disabledBtn = true
    // }
  },
  // 方法
  methods: {
    // 获取企业信息
    getEnterpriseVersion () {
      this.spinning = true
      api
        .get('/api/app/sys-enterprise-info/present-all-enterprise-info')
        .then(({ data }) => {
          this.spinning = false
          this.version = data
          const expire = new Date(data.expireTime).getTime()
          const now = new Date().getTime()
          this.pastDue = expire < now
        })
        .catch(err => {
          this.spinning = false
          console.log(err)
          // this.$message.error(err)
        })
    },
    // 升级续费
    upgradeRenewal () {
      const routeUrl = this.$router.resolve({
        path: '/UpgradeRenewal',
      })
      window.open(routeUrl.href, '_blank')
    },
    // 获取企业服务列表
    getEnterpriseServiceList () {
      this.spinning = true
      api
        .get('/api/app/sys-pay-service-on/sys-pay-service-on-detail-list')
        .then(({ data }) => {
          this.spinning = false
          this.service = data
        })
        .catch(err => {
          this.spinning = false
          console.log(err)
          // this.$message.erro(err)
        })
    },
    getEnterpriseNotAddList () {
      this.spinning = true
      api
        .get('/api/app/sys-pay-service-on/sys-pay-service-off-detail-list')
        .then(({ data }) => {
          this.spinning = false
          this.notAdd = data
        })
        .catch(err => {
          this.spinning = false
          console.log(err)
          // this.$message.erro(err)
        })
    },
    // 购买按钮
    purchaseClick (e) {
      if (this.isDemo) {
        return
      }
      this.$router.push({
        path: '/EnterpriseManagement/ProductAttribute',
        query: {
          name: e.commodityCode,
          serviceCode: e.serviceCode,
          serviceID: e.serviceID,
        },
      })
    },
    // 添加服务
    addClick (e) {
      this.spinning = true
      const obj = {
        id: e.id,
      }
      api
        .post('/api/app/sys-pay-service-on/service-try-out', obj)
        .then(({ data }) => {
          this.getEnterpriseNotAddList()
          this.getEnterpriseServiceList()
          this.$message.success('添加成功')
          this.spinning = false
        })
        .catch(err => {
          this.spinning = false
          console.log(err)
          // this.$message.erro(err)
        })
    },
    // 日志按钮
    logClick (e) {
      this.$refs.usageRecord.showRecordView(e)
    },

  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";

.version {
  letter-spacing: 1px;
  background: #fff;
  padding: 0px 0px 0px 20px;
  height: 60px;
  font-size: 16px;
  line-height: 60px;
  border-radius: 0 0 3px 3px;
  .left {
    .icon {
      margin-left: 10px;
      height: 30px;
      width: 30px;
    }
  }
  .right {
    .text {
      font-size: 16px;
      opacity: 0.5;
    }
    .button {
      cursor: pointer;

      margin-right: 20px;
      .text {
        background: rgba(255, 0, 122, 5);
        padding: 0px 10px;
      }
    }
  }
}

.service {
  margin-top: 18px;
  width: 100%;
  flex-wrap: wrap;
  .serviceItem {
    border-radius: 5px;
    background: #fff;
    width: 32%;
    // margin-right: 16px;
    margin-bottom: 18px;
    .top {
      padding: 20px;
      .title {
        font-size: 16px;
      }
      .num {
        letter-spacing: 3px;
        margin-top: 10px;
        font-size: 18px;
        font-weight: 700;
      }
      .progress {
        margin-top: 8px;
      }
      .canUse {
        letter-spacing: 1px;
        margin-top: 10px;
        font-size: 18px;
        font-weight: 700;
      }
      .can_num {
        margin: 20px 0;
      }
    }
    .bottom {
      height: 40px;
      .buttons {
        padding: 0px 20px;
        height: 100%;
        .log {
          color: black;
          opacity: 0.5;
        }
        .purchase {
          color: red;
          opacity: 0.7;
        }
        .no_purchase {
          color: rgb(97, 97, 97);
          opacity: 0.7;
        }
      }
    }
  }
  .service_noAdd {
    background-color: #fffdfd;
  }
  .serviceItem:nth-child(3n) {
    /* 尽量让item在list中居中，两边都没有margin */
    margin-right: 0;
  }
}

.no_add {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0;
}
// .service:after {
//   content: "";
//   flex: auto;
// }
.service:after {
  content: "";
  width: 32%;
  border: 1px solid transparent;
}

.ant-empty {
  margin-top: 140px;
}
::v-deep(.ant-btn) {
  border-color: #134ccf;
  color: #134ccf;
  &:hover {
    background-color: #134ccf;
    color: #fff;
  }
}
</style>
