import api from '@/api/API'

// 获取表 列结构
export const getTableColumnData = async (tableCode, module, operationFixed) =>
  api.get(`/api/app/table-column/table-column-by-code-module?tableCode=${tableCode}&module=${module}`)
    .then(({ data }) => {
      const list = data
      list.forEach(element => {
        const obj = {
          customRender: element.key,
        }
        element.slots = obj
        if (element.key === 'operation' && operationFixed === true) {
          element.fixed = 'right'
        }
      })
      return list
    })
