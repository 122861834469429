// 查看日志详情弹窗
// sle
<template>
  <div>
    <a-modal
      v-model:visible="visible"
      :maskClosable="false"
      :title="service.serviceName"
      width="1200px"
    >
      <template v-slot:footer>
        <div class="footer flex-rowReverse justify-between">
          <span class="right">
            <a-button @click="closeView">关闭</a-button>
          </span>
        </div>
      </template>
      <div class="flex-column">
        <div class="head flex-row justify-between align-center">
          <span
            >当前剩余 {{ service.remainingNum }}
            {{ service.serviceUnit }}可用</span
          >
          <a-button v-if="service.purchase" @click="purchaseClick(service)"
            >立即购买</a-button
          >
        </div>
        <a-spin :spinning="spinning">
          <a-table
            :pagination="pagination"
            @change="paginationChange"
            :columns="columns"
            :data-source="list"
            :rowKey="(record) => record.id"
            bordered
          >
            <template #discernResult="{ record }">
              <a-tooltip>
                <template #title>{{ record.discernResult }}</template>
                <span class="ecllipsis">
                  {{ record.discernResult }}
                </span>
              </a-tooltip>
            </template>
            <template #detail="{ record }">
              <a-tooltip>
                <template #title>{{ record.detail }}</template>
                <span class="ecllipsis">
                  {{ record.detail }}
                </span>
              </a-tooltip>
            </template>
          </a-table>
        </a-spin>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { getTableColumnData } from '@/api/tableAbout/tableAPI'

export default defineComponent({
  props: {
    departmentList: {
      type: Array,
    },
  },
  data () {
    return {
      module: 'record', // 当前功能模块
      spinning: false, // 是否加载
      visible: false, // 弹窗是否显示
      title: '', // 标题
      logPort: '', // 接口
      list: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 100,
      }, // 分页部分
      columns: [],
      service: {},
    }
  },
  methods: {
    // 打开弹窗
    async showRecordView (e) {
      // console.log(e)
      this.service = e
      this.visible = true
      await this.getTableColumnData(e.serviceCode)
      this.getTableData()
    },
    // 获取当前table的列信息
    async getTableColumnData (e) {
      const data = await getTableColumnData(e, 'record')
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取列结构失败')
          console.log(err)
        })
      this.columns = data
    },
    // 获取table数据
    getTableData () {
      this.spinning = true
      api
        .get(this.service.logPort, {
          params: {
            PageSize: this.pagination.pageSize,
            Pagination: this.pagination.current,
          },
        })
        .then(({ data }) => {
          this.spinning = false
          this.list = data.list
          this.pagination.total = data.pageCount
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取数据失败')
          console.log(err)
        })
    },
    // 页码变更
    paginationChange (e) {
      this.pagination.current = e.current
      this.getTableData()
    },
    // 关闭弹窗
    closeView () {
      this.visible = false
      this.pagination.current = 1
      this.pagination.total = 100
      this.spinning = false
      this.columns = []
      this.list = []
    },
    // 立即购买
    purchaseClick (e) {
      this.$router.push({
        path: '/EnterpriseManagement/ProductAttribute',
        query: {
          name: e.commodityCode,
          serviceCode: e.serviceCode,
          serviceID: e.serviceID,
        },
      })
    },
  },
})
</script>
<style  lang="scss" scoped>
@import "@/assets/common.scss";
.head {
  height: 30px;
  font-size: 15px;
  margin-bottom: 10px;
}
::v-deep(.ant-table-row-cell-break-word) {
  .ecllipsis {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
}
</style>
